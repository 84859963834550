import React from 'react';
import {myArray} from "../../utils/myArray";
import {MailContainer, Stripe, NowrapStripe} from "./styles/emailStyles";
import {dateToDDMMYY} from "../../utils/dateTime";

const EmailBody = ({basket, user}) => {

    const c = new myArray(...basket?.basket_products || [])
        .groupBy5('product_id', 'variant_id', 'additive_id', 'type_id', 'order_date')
    const bpGroupBy = Object.values(c)
        ?.map(p => ({
            ...p.at(0),
            number: p.reduce((sum, x) => sum + x.amount, 0),
            fullPrice: p.reduce((sum, x) => sum + x.price * x.amount, 0)
        }))
        .sort((a, b) => new Date(a.order_date) - new Date(b.order_date))

    const total = basket?.basket_products.reduce((sum, bp) => sum + bp.amount * bp.price, 0)

    return (
        <div>
        <img src={`${process.env.REACT_APP_API_URL}logo.svg`} alt="logo" // Это лого подгружается в письмо уже у получателя, поэтому hardcode.
             style={{marginLeft: 20, width: 100, height: 50, objectFit: 'contain'}}
        />

        <Stripe bg='transparent' h='30px'/>

                <NowrapStripe>
                    Děkujeme Vám za zájem o&nbsp;
                    <a style={{display:"inline-block", whiteSpace:"nowrap"}} href={`${process.env.REACT_APP_WEB_SITE}`}>Živý Chléb</a>
                </NowrapStripe>. Vaši objednávku jsme obdrželi a zpracujeme ji v co nejkratším čase.

        <Stripe bg='transparent' h='30px'/>

        <MailContainer border={'solid 1px #d4d4d4'}>
            <Stripe bg='#d4d4d4'>
                <Stripe bg={'transparent'} pl='8px'>Detail objednávky</Stripe>
            </Stripe>

            <Stripe bg='transparent' h='10px'/>
            <Stripe bg='transparent' h='auto'>
                <Stripe w='60%' pl='8px' h='auto'>Číslo objednávky: {basket.id}</Stripe>
                <Stripe w='40%' pl='8px' h='auto'>{user?.name} {user?.surname}</Stripe>
            </Stripe>
            <Stripe bg='transparent' h='auto'>
                <Stripe w='60%' pl='8px' h='auto'>Datum přidání: {dateToDDMMYY(basket.updatedAt)}</Stripe>
                <Stripe w='40%' pl='8px' h='auto'>E-mail: {user?.email}</Stripe>
            </Stripe>
            <Stripe bg='transparent' h='auto'>
                <Stripe w='60%' pl='8px' h='auto'>Způsob platby:</Stripe>
                <Stripe w='40%' pl='8px' h='auto'>Telefon: {user?.phone}</Stripe>
            </Stripe>
            <Stripe bg='transparent' h='10px'/>

            <div style={{overflowX: 'auto', width: '100%'}}>
                <table width='100%' border='1' style={{borderCollapse: 'collapse', borderSpacing: '0'}}>

                    <thead>
                    <tr style={{fontStyle: 'normal', backgroundColor: '#d4d4d4', height: 40, padding: "0 8px 0 8px"}}>
                        {["Produkt", "Datum", "Čas", "Množství", "Cena", "Celkem"].map((e, i) =>
                            <th key={i} style={{
                                textAlign: i > 0 ? 'right' : 'left',
                                padding: '0 8px', margin: 'auto 0'
                            }}>
                                {e}
                            </th>)}
                    </tr>
                    </thead>

                    <tbody>
                    {bpGroupBy.map(bp =>
                        <tr key={bp.id} style={{fontSize: 14, lineHeight: 1.5}}>
                            {[`${bp.product?.name} ${bp.variant?.weight}g ${bp.type?.name} ${bp.additive?.name}`,
                                dateToDDMMYY(bp.order_date), bp.issue_time || '', bp.number, bp.price, bp.fullPrice
                            ].map((e, i) =>
                                <td key={i} style={{
                                    textAlign: i > 0 ? 'right' : 'left',
                                    padding: '0 8px',
                                    whiteSpace: i < 1 ? 'normal' : 'nowrap',
                                }}>
                                    {e}{i === 3 ? " Ks" : i > 3 ? " Kč" : ""}
                                </td>
                            )}
                        </tr>
                    )}
                    </tbody>

                    <tfoot>
                    <tr style={{fontSize: '18'}}>
                        <td style={{paddingLeft: 8, textAlign: "left"}}>
                            Cena
                        </td>
                        <td colSpan="5" style={{paddingRight: 8, textAlign: "right"}}>
                            {total} Kč
                        </td>
                    </tr>
                    </tfoot>

                </table>
            </div>

        </MailContainer>
        <Stripe bg='transparent' h='40px'/>
        <Stripe h='auto'>Prosím odepište na tento e-mail v případě nejasností.</Stripe>
        <Stripe bg='transparent' h='60px'/>
    </div>);
};

export default EmailBody;
