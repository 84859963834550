import React, {createContext} from 'react';
import App from './App';
import UserStore from "./store/UserStore";
import ProductStore from "./store/ProductStore";
import './index.css'
import { createRoot } from 'react-dom/client';
import BasketStore from "./store/BasketStore";
import ProductionStore from "./store/ProductionStore";
import InfoStore from "./store/InfoStore";

const container = document.getElementById('root');

export const Context = createContext(null)

console.log(process.env.REACT_APP_API_URL)

export const productionStore = new ProductionStore()
export const infoStore = new InfoStore()

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Context.Provider value={{
        user: new UserStore(),
        productStore: new ProductStore(),
        basketStore: new BasketStore(),
        infoStore: new InfoStore()
    }}>
        <App/>
    </Context.Provider>
);

