import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from "./Shop.module.css";
import HdrCarousel from "../components/headerComponent/HdrCarousel";
import TechInfoCard from "../components/headerComponent/TechInfoCard";
import {Container, Spinner} from "react-bootstrap";
import ShopProducts from "../components/shopComponents/products/ShopProducts";
import ShopQuality from "../components/shopComponents/quality/ShopQuality";
import ShopReservation from "../components/shopComponents/reservation/ShopReservation";
import Contacts from "../components/shopComponents/contacts/Contacts";
import iconUp from "./images/icon-up.png";
import {Context, productionStore} from "../index";
import {getCSSVariableInt} from "../utils/getCSSVariable";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {useScrollControl} from "../components/myModalComponents/hooks/useScrollControl";

const Home = observer(({
                           navBarItem, setNavBarItem,
                           loading, loadingBasket, loadingQualities,
                           selectedProduct, setSelectedProduct,
                           selectedProductions, setSelectedProductions

                       }) => {
        const
            [iconUpDisplay, setIconUpDisplay] = useState("none"),
            {productStore} = useContext(Context),
            {basketStore} = useContext(Context),
            productRef = useRef(),
            qualityRef = useRef(),
            reservationRef = useRef(),
            contactRef = useRef(),
            topRef = useRef(),
            [didMount, setDidMount] = useState(false),
            [carouselInterval,setCarouselInterval] = useState(5000),
            navigate = useNavigate();

        const scroll = (element) => {
            const yOffset = -getCSSVariableInt("--header-h") + 50;
            const y = (element?.getBoundingClientRect().top || 0) + window.scrollY + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }

        const scrollSwitch = (item) => {
            scroll(getNavBarItemRef(item))
        }

        const getNavBarItemRef = (item) => {
            switch (~~item) {
                case 1:
                    return productRef.current
                case 2:
                    return qualityRef.current
                case 3:
                    return reservationRef.current
                case 4:
                    return contactRef.current
                default:
                    return topRef.current
            }
        }
        const [randomIndex,setRandomIndex] = useState(0)


        useEffect(() => {
            scrollSwitch(~~navBarItem)
            // eslint-disable-next-line
        }, [navBarItem])

// for scroll from another page
// eslint-disable-next-line
        useEffect(() => {
            const element = getNavBarItemRef(~~navBarItem)
            if (element && !didMount && !loading && !loadingBasket && !loadingQualities) {
                setDidMount(true)
                setNavBarItem(~~navBarItem)
            }
        })

        useScrollControl("", window.scrollY, () => {
            if(window.scrollY > basketStore.basketXY.innerHeight && window.innerWidth > 768) {
                setIconUpDisplay("block")
                setCarouselInterval(3600000) // stop carousel and stop re-render
            }
            else {
                setIconUpDisplay("none")
                setCarouselInterval(5000)
            }
        })

        const reserveBasketHandler = () => {
            navigate('/cart')
            basketStore.setShowBasket(false)
            window.scrollTo({top: 0, behavior: 'smooth'});
        }

        const goToReservation = (product) => {
            if (productionStore.productions.find(p => p.product_id === product.id)) {
                setSelectedProduct(product)
                scroll(reservationRef.current);
            }
        }

        if (loading || loadingBasket || loadingQualities) {
            return (
                <div>
                    <div className="d-flex justify-content-center align-items-center" style={{height: '70vw'}}>
                        <Spinner animation="border" variant="primary"/>
                    </div>
                </div>
            )
        }
        const slideChanged = () => {
            setRandomIndex(Math.floor(Math.random() * productStore.qualities.length))
        }

        return (
            <div>
                <Container fluid className="px-0">
                    <section ref={topRef}>
                        <div className={styles.headerCarousel}>
                            <HdrCarousel slideChanged={slideChanged} interval={carouselInterval}/>
                            <TechInfoCard gotoQuality={() => setNavBarItem(2)} indexToShowText={randomIndex}/>
                        </div>
                    </section>

                    {productStore.products?.length ?
                        <Container fluid className="px-0">
                            <div style={{width: '100%', height: 15, backgroundColor: 'var(--white)'}}/>
                            <Container fluid className={styles.bgLight}>
                                <section ref={productRef} className={styles.shopSectionP}>

                                    <ShopProducts goToReservation={goToReservation}/>

                                </section>
                            </Container>
                        </Container>: <div/>
                    }

                    <div style={{width: '100%', height: 15, backgroundColor: 'var(--white)'}}/>

                    {productStore.qualities?.length?
                        <Container fluid className={`px-0 ${styles.bgLight50}`}>
                            <section ref={qualityRef} className={styles.shopSection}>

                                <ShopQuality qualities={productStore.qualities}/>

                            </section>
                        </Container>: <div/>
                    }

                    <div style={{width: '100%', height: 15, backgroundColor: 'var(--white)'}}/>

                    {productStore.products?.filter(p => p.reserve).length ?
                        <Container fluid className="px-0">
                            <div style={{width: '100%', height: 15, backgroundColor: 'var(--white)'}}/>
                            <Container fluid className={`px-0 ${styles.bgLight}`}>
                                <section ref={reservationRef} className={styles.shopSection}>

                                    <ShopReservation selectedProduct={selectedProduct}
                                                     setSelectedProduct={setSelectedProduct}
                                                     selectedProductions={selectedProductions}
                                                     setSelectedProductions={setSelectedProductions}
                                                     reserveHandler={reserveBasketHandler}
                                                     navBarItemClicked={setNavBarItem}

                                    />
                                </section>
                            </Container>
                        </Container>:<div/>
                    }

                    <div style={{width: '100%', height: 15, backgroundColor: 'var(--white)'}}/>

                    <div className={styles.bgLight50}>
                        <section ref={contactRef} className={styles.shopSection}>

                            <Contacts/>

                        </section>
                    </div>

                    <img className={styles.iconUp}
                         style={{display: iconUpDisplay}}
                         src={iconUp} alt="up"
                         onClick={() => setNavBarItem(0)}
                    />

                </Container>
            </div>
        );
    })
;

export default Home;