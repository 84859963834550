import React from 'react';
import {Col, Row} from "react-bootstrap";

const WorkingDays = ({workingDays}) => {
    return (
        <div className="mb-4">
            {workingDays?.map(day =>
                    day.isOpen && (
                        <Row key={day.id} style={{fontSize: 'var(--bakery-fs6)'}}>
                            <Col xs={4}>{day.csName}</Col>
                            <Col xs={3}>{day.time_from}</Col>
                            <Col xs={3}>{day.time_to}</Col>
                        </Row>
                    )
            )}
        </div>
    );
};

export default WorkingDays;