import React, {useContext, useState} from 'react';
import {Context, productionStore} from "../../../index";
import ProductSelector from "./ProductSelector";
import ProductView from "./ProductView";
import {defaultAllProductions} from "./selectorArrays";
import {addToBasket} from "../../basketComponents/utils/basketUtils";
import {observer} from "mobx-react-lite";
import styles from "./css/ShopReservation.module.css"
import {loadProductionsAmounts} from "../../../hooks/useCheckBasketActivity";
import {Container} from "react-bootstrap";

const ShopReservation = observer(({
                                      selectedProduct,
                                      setSelectedProduct,
                                      selectedProductions,
                                      setSelectedProductions,
                                      navBarItemClicked,
                                      ...props
                                  }) => {
    const {productStore} = useContext(Context)
    const {basketStore} = useContext(Context)
    const products = productStore.products.filter(p => p.reserve)
    const [updating, setUpdating] = useState(false)

    const setProduct = (product) => {
        if (productionStore.productions.find(p => p.product_id === product.id)) {
            setSelectedProduct(product)
        }
    }


    const putToBasketHandler = () => {
        const production = selectedProductions?.length > 0 ? selectedProductions[0] : {}
        const variant_id = selectedProduct?.variants.find(v => v.weight === production?.weight)?.id

        let cnt = Date.now() % 1000000

        const sps = selectedProductions.filter(sp => sp.selectedDate)

        for (let p of sps) {
            if (p.amountR === 0) continue
            const basketProduct = basketStore.basket.basket_products.find(f => f.production_id === p.id)
            if (basketProduct) {
                basketStore.setBasket({
                    ...basketStore.basket, basket_products: basketStore.basket.basket_products
                        .map((bp => bp.production_id === p.id ? {...bp, amount: bp.amount + p.amountR} : bp))
                })
            } else {
                const selectedBasketProduct = {
                    product_id: selectedProduct.id,
                    production_id: p.id,
                    type_id: p.type_id, // it is right
                    variant_id: variant_id,
                    additive_id: p.additive_id, // it is right
                    amount: p.amountR || 0,
                    price: p.price || 0,
                    order_date: p.selectedDate || p.planned_date,
                    issue_time: p.issueInterval,
                    id: cnt++,
                    isNewRow: true,
                }
                basketStore.setBasket({
                    ...basketStore.basket,
                    basket_products: [...basketStore.basket.basket_products, selectedBasketProduct]
                })
            }
        }
        setUpdating(true)
        addToBasket(basketStore.basket)
            .then(basket => {
                basketStore.setBasket(basket)
                basketStore.setShowBlueButton(true)
                basketStore.setBlueButtonBuff(sps?.at(0) || {})
                setSelectedProductions(defaultAllProductions(products, basket))
            })
            .then(()=>{
                loadProductionsAmounts()
            })
            .catch(e => {
                    alert(`addToBasket says: ${e}`)
                }
            )
            .finally(() => {
                setUpdating(false)
            })

        navBarItemClicked(3)
        //basketStore.setShowBasket(true)
    }

    return (
        <Container fluid className="px-md-0">
            <div className="shopSectionName">
                {selectedProduct?.name || "Objednávka"}
            </div>
            <div className={`${styles.container} d-block d-md-flex justify-content-md-between`}>

                <div className={styles.column1}>
                    <div className={styles.productH}>
                        Viverti si chléb:
                    </div>
                    {products.map(product =>
                        <div key={product.id}>
                            <div>
                                <div
                                    className={product.id === selectedProduct.id ? styles.productSelected : styles.product}
                                    onClick={() => setProduct(product)}
                                >
                                <span onClick={() => setProduct(product)}>
                                    {product.name}
                                </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles.column2}>
                    <ProductSelector
                        product={selectedProduct}
                        setProduct={setSelectedProduct}
                        selectedProductions={selectedProductions}
                        setSelectedProductions={setSelectedProductions}
                        {...props}
                    />
                </div>

                <div className={styles.column3}>
                    <ProductView product={selectedProduct}
                                 productions={selectedProductions}
                                 putToBasket={putToBasketHandler}
                                 updating={updating}
                                 {...props}
                    />
                </div>
            </div>
        </Container>
    );
});

export default ShopReservation;