/**
 * Vrati cas do zavirani pro dany den
 * @returns leftTime: number- zbyvajici cas do zavirani (ms)
 * @param fromToArray - pole oteviraci doby pro kazdy den v tydnu
 * @example howLongUntilClosingArr( ['','','','10:00-20:00','10:00-20:00','10:00-20:00','10:00-16:00',''])
 */
const howLongUntilClosingArr = (fromToArray) => {
    const openHours = fromToArray?.at(new Date().getDay())
    return howLongUntilClosing(openHours)
}


/**
 * Возвращает оставшееся время до окончания выдачи для данного дня
 * @param {string} openHours - Часы работы в формате 'HH:MM-HH:MM'
 * @returns {Object} { dateClose: Date, leftTime: number } - Дата закрытия и оставшееся время в миллисекундах
 * @example howLongUntilClosing('10:00-20:00')
 */
const howLongUntilClosing = (openHours) => {
    const now = new Date();

    // Если `openHours` отсутствует или не содержит '-', возвращаем 0
    if (!openHours || !openHours.includes('-')) {
        return { dateClose: now, leftTime: 0 };
    }

    const closingTime = openHours.split('-')[1]; // Получаем время закрытия
    const [hClose, mClose] = closingTime.split(':').map(Number); // Разделяем на часы и минуты

    if (isNaN(hClose) || isNaN(mClose)) {
        return { dateClose: now, leftTime: 0 }; // Защита от ошибок
    }

    // Устанавливаем дату закрытия с текущим днём
    const dateClose = new Date(now);
    dateClose.setHours(hClose, mClose, 0, 0);

    // Вычисляем оставшееся время до закрытия (если уже прошло, возвращаем 0)
    const leftTime = Math.max(dateClose - now, 0);

    return { dateClose, leftTime };
};

// 🔹 Примеры использования:
console.log(howLongUntilClosing("10:00-20:00")); // { dateClose: ..., leftTime: X ms }
console.log(howLongUntilClosing("10:00-15:00")); // Если сейчас 16:00, вернёт leftTime: 0
console.log(howLongUntilClosing("10:00-abc")); // Ошибочный формат, вернёт leftTime: 0
console.log(howLongUntilClosing("")); // Некорректный ввод, вернёт leftTime: 0
/**
 * Minimalni cas do zavirani
 * @type {number}
 */
const leftTimeMinimum = 0
//const leftTimeMinimum = 1000 * 60 * 60 // 1 hour


/**
 * Возвращает время выдачи заказов на сегодня в формате ['HH:MM-HH:MM'] - без учета часов работы магазина - только по времени выдачи в настройках продукта.
 * @param {Array} products - массив продуктов
 * @param {Object} production - объект производства
 * @returns {string[]} - массив с одним элементом (часы работы) или пустой массив
 */
const todayOpenHours = (products, production) => {
    const product = products.find(p => p.id === production?.product_id);
    const variant = product?.variants.find(v => v.weight === production?.weight);
    const vt = variant?.variant_types?.find(vt => vt.type_id === production?.type_id);

    // Формируем строку времени открытия-закрытия
    const todayOpenFromTo = (vt?.time_from && vt?.time_to) ? `${vt.time_from}-${vt.time_to}` : null;

    // Если время работы отсутствует, возвращаем пустой массив
    if (!todayOpenFromTo) return [];

    // Получаем оставшееся время до закрытия
    const { leftTime } = howLongUntilClosing(todayOpenFromTo);

    return leftTime > leftTimeMinimum ? [todayOpenFromTo] : [];
};

const isTimeUntilClosing = (products, production) => {
    const today = new Date();
    const productionDate = new Date(production?.planned_date); // дата выпечки

    if (today.toDateString() === productionDate.toDateString()) {
        // 🔹 Сегодняшний заказ
        return todayOpenHours(products, production).length > 0;
    }
    return true;
};

export {
    howLongUntilClosing,
    howLongUntilClosingArr,
    todayOpenHours,
    isTimeUntilClosing
}
