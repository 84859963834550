import {useEffect} from "react";
import {getContentActivityAPI} from "../http/activityAPI";
import {fetchTypes} from "../http/typeAPI";
import {fetchAdditives} from "../http/addtiveAPI";
import {fetchAllergens} from "../http/allergenAPI";
import {fetchIntervals} from "../http/intervalAPI";
import {fetchProductionAll} from "../http/productionAPI";
import {productionStore} from "../index";
import {fetchBigProducts} from "../http/productAPI";
import {fetchQualities} from "../http/qualityAPI";
import {fetchFirm} from "../http/firmAPI";


export const loadDirectories = (productStore,infoStore) => {
    fetchTypes().then(data => productStore.setTypes(data))
    fetchAdditives().then(data => productStore.setAdditives(data))
    fetchAllergens().then(data => productStore.setAllergens(data))
    fetchIntervals().then(data => infoStore.setIntervals(data))
    fetchFirm().then(data => infoStore.setFirm(data))
}

export const loadProductions = (productStore, setLoading) => {
    fetchProductionAll()
        .then(productions => productionStore.setProductions(productions))
        .then(() => fetchBigProducts())
        .then(products => {
            productStore.setProducts(products.filter(p => p.status === "ShowInTheShop"));
        })
        .catch(e => alert(`useLoadProductions says:  ${e}`))
        .finally(() => setLoading ? setLoading(false) : {})
}

export const loadQualities = (productStore, setLoadingQualities) => {
    fetchQualities()
        .then((data) => {
            productStore.setQualities(data)
        })
        .catch(e => alert(e))
        .finally(() => setLoadingQualities(false))
}


export const useLoadAll = (callBack) => {
    //eslint-disable-next-line
    useEffect(() => callBack(), [])
    useEffect(() => {
        const i = setInterval(
            () => {
                getContentActivityAPI()
                    .then(b => {
                        b && callBack()
                    })
                    .catch(e => console.log("getContentActivity error: " + e))
            }, 1000 * (process.env.REACT_APP_SCREEN_UPDATE_REQUEST_SEC || 30) // 30 sec
        )
        return () => clearInterval(i)
        //eslint-disable-next-line
    }, [])
}
