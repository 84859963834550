import React, {useState} from 'react';
import styles from './Footer.module.css'
import logo from './img/logo.svg'
import instIcon from "../img/instagramIcon.svg";
import fbIcon from "../img/fbIcon.svg"
import PrivacyModal from "../../privacyPolicyComponents/PrivacyModalCompanent";
const Footer = ({navBarItemClicked, navBarMenu, logoX}) => {

    const menu = (navBarMenu?.map(m => ({id: m.id, name: `.${m.name}`})) || [])
    menu.unshift({id: 0, name: ".Domu"})

    const [isGdprAgrmntShow, setIsGdprAgrmntShow] = useState(false)

    return (
        <div className={styles.footer}>
            <div className={styles.footerCont} style={{left: logoX}}>
                <div className={styles.c1}>
                    <img src={logo} alt={"logo"} className={styles.logo}/>
                    <div className={styles.c12}>
                        <a className={styles.fb}
                             href={'https://www.facebook.com/profile.php?id=61557720657941'}
                             target="_blank"
                             rel="noreferrer"
                        >
                            <img src={fbIcon} alt={'fb'}/>
                        </a>
                        <a className={styles.instagram}
                           href='https://www.instagram.com/zivychleb'
                           target="_blank"
                           rel="noreferrer"
                        >
                            <img src={instIcon} alt={'Instagram'}/>
                        </a>
                    </div>
                </div>
                <div className={styles.c2}>

                    <div className={styles.navbarMenu}>
                        {menu?.map(n =>
                            <span
                                key={n.name}
                                onClick={() => navBarItemClicked(n.id)}
                            >
                                {n.name}
                            </span>
                        )}
                    </div>

                    <div className={styles.c22}>
                        <span>
                            {new Date().getFullYear()} © Pekárna Živý Chléb
                        </span>
                    </div>
                </div>
                <div className={styles.cGDPR}>
                    { isGdprAgrmntShow?
                        <PrivacyModal show={isGdprAgrmntShow}   setShow={setIsGdprAgrmntShow}/>:
                        <span onClick={() => setIsGdprAgrmntShow(true)}>
                            Ochrana osobních údajů
                        </span>
                    }
                </div>
            </div>
        </div>
    );
};

export default Footer;