import React, { useState } from 'react';
import styles from "./Calendar.module.css";
import { daysOfWeekCZ, monthNames } from "../../utils/daysOfWeek";
import { calendarArr, possibleToSelect, styleObj } from "./calendarArr";
import { dateCompare } from "../../utils/dateTime";

/**
 * Calendar component
 * @param possibleProductions - array of possible productions
 * @param closeHandler - function to close calendar
 * @param okHandler - function to confirm selected dates
 * @param sps - array of selected productions
 * @param setSps - function to set selected productions
 * @returns {Element} - calendar component
 * @constructor - Calendar
 */
const Calendar = ({ possibleProductions, closeHandler, okHandler, sps, setSps }) => {
    const [date, setDate] = useState(() => {
        const today = new Date();
        today.setDate(1);
        return today;
    });

    const possibleDates = possibleProductions?.map(p => new Date(p.planned_date)) || [];
    const today = new Date();
    let arr = calendarArr(date);

    // 🔹 Исправленный обработчик назад (не переходит за текущий месяц)
    const backClickHandler = () => {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() - 1);
        newDate.setDate(1);

        const firstDayOfCurrentMonth = new Date();
        firstDayOfCurrentMonth.setDate(1);
        firstDayOfCurrentMonth.setHours(0, 0, 0, 0);

        if (newDate < firstDayOfCurrentMonth) return;

        arr = calendarArr(newDate);
        setDate(newDate);
    };

    // 🔹 Исправленный обработчик вперёд
    const forwardClickHandler = () => {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + 1);
        newDate.setDate(1);

        arr = calendarArr(newDate);
        setDate(newDate);
    };

    // 🔹 Оптимизированный обработчик выбора даты
    const dateClickHandler = (arrItem) => {
        if (!possibleToSelect(today, arrItem.date, possibleDates)) return;

        const production = possibleProductions?.find(p => dateCompare(p.planned_date, arrItem.date) === 0);
        if (!production) return;

        const existingProduction = sps.find(sp => sp.id === production.id);
        if (existingProduction) {
            setSps(sps.map(sp => (sp.id === production.id ? { ...sp, selectedDate: production.planned_date } : sp)));
        } else {
            setSps([...sps, { ...production, selectedDate: production.planned_date }]);
        }
    };

    return (
        <div className={styles.calendarBox}>
            <div className={styles.month}>
                <div style={{ cursor: 'pointer' }} onClick={backClickHandler}>
                    {`<`}&nbsp;&nbsp;
                </div>
                <div> {`${monthNames[date.getMonth()]} ${date.getFullYear()}`}</div>
                <div style={{ cursor: 'pointer' }} onClick={forwardClickHandler}>
                    &nbsp;&nbsp;{` >`}
                </div>
            </div>
            <table className={styles.table}>
                <tbody>
                <tr className={styles.week}>
                    {daysOfWeekCZ.map(i => (
                        <td key={i.name} style={{ fontSize: 14, paddingBottom: 5 }}>
                            {i.name}
                        </td>
                    ))}
                </tr>
                {[0, 1, 2, 3, 4, 5].map(i => (
                    <tr key={i}>
                        {[0, 1, 2, 3, 4, 5, 6].map(j => {
                            const cellData = arr.at(i * 7 + j);
                            return (
                                <td
                                    key={j}
                                    style={styleObj(cellData, sps.map(sp => sp.selectedDate), possibleDates, today)}
                                    onClick={() => dateClickHandler(cellData)}
                                    className={styles.td}
                                >
                                    {cellData.day}
                                </td>
                            );
                        })}
                    </tr>
                ))}
                </tbody>
            </table>

            <div className={styles.footer}>
                <div style={{ color: '#bfbfbf' }} onClick={closeHandler}>
                    Zrušit
                </div>
                <div style={{ color: 'var(--warning)' }} onClick={okHandler}>
                    Vybrat
                </div>
            </div>
        </div>
    );
};

export default Calendar;