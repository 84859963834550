import React, {useContext, useEffect} from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import {ButtonGroup} from "react-bootstrap";
import {MyDropdownToggle} from "../../UI/MyDropdownToggle";
import {Context} from "../../../index";
import {todayOpenHours} from "../../../utils/howLongUntileClosing";
import {isToday} from "../../../utils/dateTime";

const TimeSelector = ({
                          production,
                          setAmountHandler,
                          selectedProductions,
                          setTimeHandler,
                          timeStyle,
                          isSelectCompleted,
                          ...props
                      }) => {


    const {productStore} = useContext(Context)
    const {infoStore} = useContext(Context)

    let issueIntervals = infoStore.intervals.map(i => i.name)

    if (isToday(production?.selectedDate)) {
        // today order
        issueIntervals = todayOpenHours(productStore.products, production)
    }

    useEffect(() => {
        if(issueIntervals.length === 1)
            setTimeHandler(production, issueIntervals[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issueIntervals.length])

    const isDateSelectCompeted = (p) => isSelectCompleted && p.selectedDate

    return (
        <div>
            <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle as={MyDropdownToggle}
                >
                    <div className={timeStyle}>
                                    <span>
                                        {production?.issueInterval  || "Vybrat"}
                                    </span>
                    </div>
                </Dropdown.Toggle>

                {isDateSelectCompeted(production) && issueIntervals.length >1 ?
                    <Dropdown.Menu style={{minWidth: props?.width || undefined}}>
                        {issueIntervals.map(interval =>
                            <Dropdown.Item key={interval}
                                           className={'d-flex justify-content-center'}
                                           onClick={() => {
                                               setTimeHandler(production, interval)
                                           }}
                            >
                                {interval}
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                    :
                    <></>
                }
            </Dropdown>

        </div>
    )
};
export default TimeSelector;