import React, {useContext, useEffect, useState} from 'react';
import {Context, productionStore} from "../../../index";
import Basket from "../../basketComponents/Basket";
import DateSelector from "./DateSelector";
import {observer} from "mobx-react-lite";
import styles from "./css/ProductSelector.module.css"
import {plusMinusButtonHandler} from "../../basketComponents/utils/pluseMinusButtonHandler";
import {distinct} from "../../../utils/myArray";
import PropertySelector from "./PropertySelector";

const ProductSelector =
    observer(({product, setProduct, selectedProductions, setSelectedProductions, ...props}) => {

        const [loadingBasket, setLoadingBasket] = useState(false)
        const [buttonId, setButtonId] = useState("")
        const {basketStore} = useContext(Context)
        const loadingBasketSpinner = {loadingBasket, buttonId, setButtonId}

        const productions = productionStore.productions.filter(p => p.product_id === product?.id)

        const distinctProductions = distinct(productions, "weight")
        const distinctProduction = distinctProductions?.[0]?.["weight"]

        useEffect(() => {
            if (distinctProductions?.length === 1) {
                selectProductionHandler("weight", distinctProduction);
            }
            // eslint-disable-next-line
        }, [distinctProduction]);

        const selectProductionHandler = (key, value) => {
            const selProd0 = selectedProductions?.at(0)
            let keys = ["weight", "type_id", "additive_id"]
            basketStore.setShowBlueButton(false)

            let newPs = (selectedProductions?.at(0)?.[`${key}Selected`] && selectedProductions?.at(0)?.[key] === value) ? selectedProductions : productions

            switch (key) {
                case "weight":
                    newPs = productions
                    newPs = newPs.filter(p => p.weight === value)
                        .map(p => ({...p, weightSelected: true}))

                    break;
                case "type_id":
                    if (!selProd0?.weightSelected) return

                    newPs = newPs.filter(p => p.weight === selProd0.weight && p.type_id === value)
                        .map(p => ({...p, type_idSelected: true}))

                    break;
                case "additive_id":
                    if (!selProd0?.type_idSelected) return
                    newPs = newPs.filter(p => p.weight === selProd0.weight && p.type_id === selProd0.type_id && p.additive_id === value)
                        .map(p => ({...p, additive_idSelected: true}))

                    break;
                default:
                    break;
            }


            keys.forEach(k => {
                if (distinct(newPs, k).length === 1) {
                    newPs = newPs.map(p => ({...p, [`${k}Selected`]: true,}))
                }
            })

            if (newPs && newPs.length === 0) return

            newPs = newPs.map(n => ({...n, selectedDate: false}))

            setSelectedProductions(newPs.slice(0, 1))
        }

        const clickedPlusMinusButton = (basketProductId, amount) => {
            plusMinusButtonHandler(basketProductId, amount, basketStore, setLoadingBasket)
        }

        return (<div className={styles.container}>

                <Basket clickedPlusMinusButton={clickedPlusMinusButton}
                        loadingBasketSpinner={loadingBasketSpinner}
                        {...props}
                />
                <div className={styles.header}>Vyberte variantu a množství:</div>
                {[{name: "Hmotnost", propertyKey: "weight"}, {name: "Ve tvařu", propertyKey: "type_id"}, {
                    name: "Posyp",
                    propertyKey: "additive_id"
                },].map(({name, propertyKey}) => <div key={name} className="d-inline d-md-flex"
                                                      style={{marginBottom: 12}}>
                    <div className={styles.name}>
                        {name}
                    </div>
                    <div className="w-100 d-flex justify-content-center justify-content-sm-end">
                        <PropertySelector
                            propertyKey={propertyKey}
                            productions={productions}
                            selectedProductions={selectedProductions}
                            setSelectedProductions={selectProductionHandler}
                            md={6}
                        />
                    </div>
                </div>)}

                <div className={styles.header2}>
                    Vyzvedněte si na prodejně:
                    <div className={styles.CombinedShape}>?</div>
                </div>

                <DateSelector
                    productions={productions}
                    selectedProductions={selectedProductions}
                    setSelectedProductions={setSelectedProductions}
                />
                <div style={{width: "100%", height: "20px"}}/>

            </div>);
        }
    )
;

export default ProductSelector;
