import {makeAutoObservable} from "mobx";

export default class InfoStore{
    constructor(){
        this._firm = {address:{}, branches:[]};
        this._intervals = [];
        makeAutoObservable(this);
    }
    setFirm(firm){
        this._firm = firm
    }
    get firm(){
        return this._firm
    }
    get openDays(){
        return this._firm.branches?.working_intervals.filter(day => day.isOpen) || ''
    }
    get openHours(){
        return this.getTodayWorkingInterval(this._firm.branches?.at(0)?.working_intervals) || ''
    }
    setIntervals(intervals){
        this._intervals = intervals
    }
    get intervals(){
        return this._intervals
    }
    get workingDays(){
        return this._firm.branches?.at(0)?.working_intervals || []
    }

    getTodayWorkingInterval = (workingIntervals) => {
        const daysMap = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const today = daysMap[new Date().getDay()]; // Получаем код дня недели (например, 'Mon')
        return workingIntervals.find(day => day.dayOfWeek === today && day.isOpen) || null; // Найти или вернуть null
    };
}